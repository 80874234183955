// Here you can add other styles
//colors
$white: white;

.loading-indicator {
  &:before {
    content: "";
    background: #000000cc;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
  }
  &:after {
    content: "Loading";
    position: fixed;
    width: 100%;
    top: 50%;
    left: 0;
    z-index: 1001;
    color: $white;
    text-align: center;
    font-weight: bold;
    font-size: 1.5rem;
  }
}

.ul2 {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
}
.ul3 {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
}
.ul4 {
  -webkit-column-count: 4;
  -moz-column-count: 4;
  column-count: 4;
}
.without-bullet {
  list-style-type: none;
  padding: 0;
}
.imei li {
  padding-left: 5px;
  background-color: #fbfbfb;
  margin: 0 0 15px 0;
}

/* ======== TIMELINE =========== */
/* The actual timeline (the vertical ruler) */
.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: "";
  position: absolute;
  width: 3px;
  background-color: #b5b0b0;
  top: 0;
  bottom: 0;
  margin-left: -5px;
}

/* Container around content */
.timeline-container {
  padding: 1px 40px;
  position: relative;
  background-color: inherit;
  width: 100%;
}

/* The circles on the timeline */
.timeline-container::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #c3bdbd;
  border: 3px solid #b5b0b0;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
  left: -14px;
}

/* Add arrows to the right timeline-container (pointing left) */
.timeline-container::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 15px;
  width: 0;
  z-index: 1;
  left: 30px;
  border: medium solid #f1f1f1;
  border-width: 10px 10px 10px 0;
  border-color: transparent #f1f1f1 transparent transparent;
}

/* The actual content */
.timeline-content {
  padding: 20px 30px;
  background-color: #f1f1f1;
  position: relative;
  border-radius: 6px;
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 600px) {
  /* Place the timelime to the left */
  .timeline::after {
    left: 31px;
  }

  /* Full-width timeline-containers */
  .timeline-container {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
    left: 0%;
  }

  /* Make sure that all arrows are pointing leftwards */
  .timeline-container::before {
    left: 60px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
  }

  /* Make sure all circles are at the same spot */
  .timeline-container::after {
    left: 15px;
  }
}

.horizontal-tabs.nav-tabs {
  overflow-x: auto;
  overflow-y:hidden;
  flex-wrap: nowrap;

  .nav-link {
    white-space: nowrap;
  }
}
